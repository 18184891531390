import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Organization from '../components/Organization';

export default function Organizations() {
  const { getAccessTokenWithPopup } = useAuth0();
  const [accessToken, setAccessToken] = useState(null);
  const [orgInfo, setOrgInfo] = useState(null);

  useEffect(() => {
    (async () => {
      let accessTokenFromPopup = await getAccessTokenWithPopup({
        authorizationParams: {
          audience: 'api://padi-services',
          scope: 'manage:organizations',
        },
      });
      console.log(accessTokenFromPopup);
      setAccessToken(accessTokenFromPopup);
      const getOptions = {
        headers: {
          Authorization: `Bearer ${accessTokenFromPopup}`,
        },
      };
      let res = await fetch(
        'https://padi-backend.vercel.app/api/orgs',
        getOptions
      );
      if (res.status !== 200) {
        return;
      }
      res = await res.json();
      console.log(res);
      setOrgInfo(res.orgs);
    })();
  }, []);

  return (
    <div id="org-main">
      <style>
        {`
                    #org-main {
                        // border: 1px green solid;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                    }
                    #token {
                        // border: 1px red solid;
                        width: 50%;
                        overflow: scroll;
                        word-wrap: break-word;
                    }
                    #token::-webkit-scrollbar {
                        display: none;
                    }
                    #organizations-div {
                        // border: 1px red solid;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                    }
                    #organizations {
                        // border: 1px red solid;
                        display: flex;
                        margin: 25px;
                        width: 100%;
                        justify-content: space-around;
                        flex-wrap: wrap;
                    }
                    .org-div {
                        // width: 100%;
                        
                        display: flex;
                        flex-direction: column;
                        justify-content: space-around;
                        align-items: center;
                        padding: 25px;
                        border-radius: 25px;
                        
                    }
                    .org-img {
                        width: auto;
                        height: 150px;
                        border-radius: 25px;
                    }
                    .invite-input {
                        border-radius: 15px;
                        text-align: center;
                        padding: 5px;
                        margin: 3px;
                        font-size: 21px;
                        width: 350px;
                    }
                    .invite-button {
                        border-radius: 10px;
                        padding: 6px;
                        margin: 5px;
                        font-size: 18px;
                        font-weight: bolder;
                    }
                `}
      </style>
      {orgInfo && Array.isArray(orgInfo) ? (
        <div id="organizations-div">
          <h1>Organizations</h1>
          <div id="organizations">
            {orgInfo.map((org) => (
              <Organization org={org} />
            ))}
          </div>
        </div>
      ) : orgInfo ? (
        <Organization org={orgInfo} />
      ) : (
        ''
      )}
    </div>
  );
}
