import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';

import Loading from './components/Loading';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import Home from './views/Home';
import Profile from './views/Profile';
import Permissions from './views/Permissions';
import Collect from './views/Collect';
import Login from './views/Login';
import Organizations from './views/Organizations';
import { useAuth0 } from '@auth0/auth0-react';
import history from './utils/history';

// styles
import './App.css';

// fontawesome
import initFontAwesome from './utils/initFontAwesome';
initFontAwesome();

const App = () => {
  const { isLoading, error } = useAuth0();

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Router history={history}>
      <style>
        {`
            #bg-img {
              // height: 100vh;
              // width: 100vw;
              // width: 100%;
              // height: 100%;
              // height: 100vh;
              z-index: -5;
              position: absolute;
              opacity: 0.27;
              object-fit: cover;
              
            }
            #app {
              // border: 3px red solid;
              // width: 100%;
            }
          `}
      </style>

      <div id="app" className="d-flex flex-column h-100">
        {/* <img
          src="https://images.pexels.com/photos/7181273/pexels-photo-7181273.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
          id="bg-img"
        /> */}
        {/* <NavBar /> */}
        <div className="main-container">
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/profile" component={Profile} />
            <Route path="/permissions" component={Permissions} />
            <Route path="/collect" component={Collect} />
            <Route path="/login" component={Login} />
            <Route path="/organizations" component={Organizations} />
          </Switch>
        </div>
        {/* <Footer /> */}
      </div>
    </Router>
  );
};

export default App;
