import { useState, useEffect } from 'react';
export default function Collect() {
  let url = new URL(window.location.href);
  let searchParams = new URLSearchParams(url.search);
  const [state, setState] = useState(searchParams.get('state'));
  const [firstName, setFirstName] = useState(searchParams.get('firstName'));
  const [lastName, setLastName] = useState(searchParams.get('lastName'));
  const [dob, setDob] = useState(searchParams.get('dob'));
  const [address, setAddress] = useState(searchParams.get('address'));
  const [city, setCity] = useState(searchParams.get('city'));
  const [country, setCountry] = useState(searchParams.get('country'));
  const [zipcode, setZipcode] = useState(searchParams.get('zipcode'));

  const submitForm = () => {
    const url = `https://padi-demo.us.auth0.com/continue?state=${state}&firstName=${firstName}&lastName=${lastName}&dob=${dob}&address=${address}&city=${city}&country=${country}&zipcode=${zipcode}`;
    window.location.href = url;
  };
  const skip = () => {
    window.location.href = `https://padi-demo.us.auth0.com/continue?state=${state}`;
  };

  useEffect(() => {
    // let url = new URL(window.location.href);
    // let searchParams = new URLSearchParams(url.search);
    // let s = searchParams.get('state');
    // let fn = searchParams.get('firstName');
    // let ln = searchParams.get('lastName');
    // let d = searchParams.get('dob');
    // let addr = searchParams.get('address');
    // let c = searchParams.get('city');
    // let cn = searchParams.get('country');
    // let z = searchParams.get('zipcode');
    // if (s) {
    //   setState(s);
    // }
  }, []);
  return (
    <div id="collect-form">
      <h2 id="collect-title">We just need a little more info...</h2>
      <style>
        {`
                    #collect-form {
                      // border: 1px red solid;
                      width: 65%;
                        display:flex;
                        flex-direction:column;
                        overflow: scroll;
                        -ms-overflow-style: none;  /* IE and Edge */
                        scrollbar-width: none;  /* Firefox */
                    }
                    #collect-form::-webkit-scrollbar {
                        display:none;
                    }
                    #button-group {
                        // border: 1px red solid;
                        display:flex;
                        width:100%;
                        margin-bottom: 30px;
                       
                    }

                    #button-group > button {
                        // width: 100%;
                        margin:10px;
                        flex-grow: 1;
                        font-size: 18px;
                        background: white;
                        font-weight: bolder;
                        padding: 5px;
                        border-radius: 15px;
                    }

                    #submit:hover {
                        background-color: #006BC8;
                        color: white;
                    }

                    #skip:hover {
                        background-color: red;
                        color: white;
                    }
                    #collect-title {
                        align-self: center;
                    }
                    #collect-form input {
                        margin-bottom: 25px;
                    }
                `}
      </style>
      <label htmlFor="firstName">First Name</label>
      <input
        name="firstName"
        placeholder="Enter your first name"
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
      ></input>
      <label htmlFor="lastName">Last Name</label>
      <input
        name="lastName"
        placeholder="Enter your last name"
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
      ></input>
      <label htmlFor="dob">DOB</label>
      <input
        name="dob"
        placeholder="Enter your date of birth"
        value={dob}
        type="date"
        min="1960-01-01"
        max="2010-01-01"
        onChange={(e) => setDob(e.target.value)}
      ></input>
      <label htmlFor="address">Address</label>
      <input
        name="address"
        placeholder="Enter your home address"
        value={address}
        onChange={(e) => setAddress(e.target.value)}
      ></input>
      <label htmlFor="city">City</label>
      <input
        name="city"
        placeholder="Enter your city"
        value={city}
        onChange={(e) => setCity(e.target.value)}
      ></input>
      <label htmlFor="country">Country</label>
      <input
        name="country"
        placeholder="Enter your country"
        value={country}
        onChange={(e) => setCountry(e.target.value)}
      ></input>
      <label htmlFor="zipcode">Zipcode</label>
      <input
        name="zipcode"
        placeholder="Enter your zipcode"
        value={zipcode}
        onChange={(e) => setZipcode(e.target.value)}
      ></input>
      <div id="button-group">
        {state && firstName && (
          <button id="submit" onClick={submitForm}>
            Submit
          </button>
        )}
        <button id="skip" onClick={skip}>
          Skip
        </button>
      </div>
    </div>
  );
}
