import React, { Component } from 'react';

import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import contentData from '../utils/contentData';

class Content extends Component {
  render() {
    return (
      <>
        <style>
          {`
          .content {
            text-align: center;
            // color: white;
            color: #006BC8;
            // background: #000;
            // background-color: #fff;
            width: 100%;
            // height: 100%;
            // padding: 30px;
          }
          ul {
            list-style-type: none;
            margin-bottom: 50px;
          }
        `}
        </style>

        <div className="next-steps my-5 content">
          <img
            className="logo"
            src="https://images.rbxcdn.com/b3265c712cb9adef3d30b52a1711e387-roblox_logo_light_08292022.svg"
          />
          {/* <h2 className="my-5 text-center">Welcome</h2> */}
          {/* <h3>Upgrade your Certification Card</h3>
          <ul>
            <li>
              Sign up for PADI Club and get 25% off one of our NEWEST limited
              edition certification card designs made from recycled materials.
              Stay connected and protect our blue planet with Club! 5% of every
              Club membership is donated to PADI AWARE.
            </li>
          </ul> */}
          {/* <Row className="d-flex justify-content-between">
            {contentData.map((col, i) => (
              <Col key={i} md={5} className="mb-4">
                <h6 className="mb-3">
                  <a href={col.link} target="_blank">
                    <FontAwesomeIcon icon="link" className="mr-2" />
                    {col.title}
                  </a>
                </h6>
                <p>{col.description}</p>
              </Col>
            ))}
          </Row> */}
        </div>
      </>
    );
  }
}

export default Content;
