import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

export default function Organization({ org }) {
  const { getAccessTokenWithPopup } = useAuth0();
  const [accessToken, setAccessToken] = useState(null);
  const [invitee, setInvitee] = useState('');
  const [inviteRole, setInviteRole] = useState('rol_XqHM1VmsDVs0zVl1');
  const invite = async (org_id, org_display_name) => {
    let invite_to_org_options = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        org_id,
        org_display_name,
        invitee,
        invite_roles: [inviteRole],
      }),
    };
    const invite_to_org_url = `https://padi-backend.vercel.app/api/orgs`;
    await fetch(invite_to_org_url, invite_to_org_options);
    alert(`Invited ${invitee} to ${org_display_name}`);
    setInvitee('');
    setInviteRole('rol_XqHM1VmsDVs0zVl1');
  };

  return (
    <div
      key={org.id}
      style={{
        backgroundColor: `${org.branding.colors.page_background}`,
        color: `${org.branding.colors.primary}`,
      }}
      className="org-div"
    >
      <img src={org.branding.logo_url} className="org-img" />
      <h2>{org.display_name}</h2>
      <input
        placeholder="Invitee Email Address"
        value={invitee}
        onChange={(e) => setInvitee(e.target.value)}
        className="invite-input"
      ></input>
      <select
        name="roles"
        value={inviteRole}
        onChange={(e) => setInviteRole(e.target.value)}
        className="invite-input"
      >
        <option value="rol_3byZwkG9SJ53K1Bd">Instructor</option>
        <option value="rol_8dsIX75Eb0kVqAlI">Student</option>
        <option value="rol_XqHM1VmsDVs0zVl1">Guest</option>
      </select>
      <button
        onClick={async () => await invite(org.id, org.display_name)}
        className="invite-button"
      >
        Invite to Org
      </button>
    </div>
  );
}
