import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';

import Highlight from '../components/Highlight';
import Loading from '../components/Loading';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import jwt_decode from 'jwt-decode';

export const ProfileComponent = () => {
  const { user, logout, getAccessTokenSilently } = useAuth0();

  const [accessToken, setAccessToken] = useState(null);
  const [decodedAccessToken, setDecodedAccessToken] = useState(null);

  useEffect(() => {
    (async () => {
      let at = await getAccessTokenSilently();
      setAccessToken(at);
      let decode = jwt_decode(at);
      setDecodedAccessToken(decode);
    })();
  }, []);

  // console.log(user);
  return (
    <>
      <style>
        {`
        .profile-container {
          // color: #006BC8;
          // border: 1px red solid;
          height: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;
          background-image: url(https://images.rbxcdn.com/782b7fc18a24ee997efd9a7f02fa4bf9-bg_08072019.jpg);
          background-position: 50%;
          background-repeat: no-repeat;
          background-size: cover;
          // align-items: center;
          justify-content: center;
          // overflow: scroll;
        }
        .profile-darken {
          height: 100%;
          width: 100%;
          background-color: rgba(0,0,0,0.9);
          position: absolute;
        }

        input {
          // width: 40%;
          max-width: 300px;
          margin: 15px;
          padding: 8px;
          border-radius: 8px;
        }
        .logout-btn {
          position: absolute;
    right: 20px ;
    top: 20px;
    width: 20%;
    max-width: 100px;
    border-radius: 8px;
    font-weight: 500;
    min-width: 140px;
    border-color: #fff;
    z-index: 2;
        }

        .update-btn {
          width: 20%;
          max-width: 100px;
    border-radius: 8px;
    font-weight: 500;
    min-width: 140px;
    border-color: #fff;
    margin: 15px 0 25px 0;
        }

        .profile-content{
          z-index: 1;
          color: white;
          // border: 1px red solid;
          display: flex;
          flex-direction: column;
          // justify-content: center;
          align-items: center;
          // margin: 5%;
          // margin-top: 100px;
          overflow: scroll;
            text-wrap: wrap;
        }

        .profile-content::-webkit-scrollbar {
          display: none;
        }

        .welcome-title {
          font-size: 3em;
          margin: 5%;
          text-transform: uppercase;
        }

        .profile-logo {
          z-index: 1;
          position: absolute;
          left: 0;
          top: 0;
          margin: 25px;
        }

        .tokenContainer {
          // border: 1px purple solid;
          width: 100%;
          display: flex;
          justify-content: center;
        }

        .divider {
          width: 100%;
          border: 1px white solid;
          height: 1px;
          margin-top: 25px;
          margin-bottom: 25px;
          max-width: 70%;
          align-self: center;
        }

        @media (max-width:500px) {
          .logout-btn {
            top: 100px;
          }

          .profile-content {
            // border: 1px red solid;
            height: 400px;
            margin: 0;
            // display: block;
            justify-content: flex-start;
            align-items: flex-start;
            overflow-y: scroll;
            overflow-x: hidden;
            text-wrap: wrap;
            
          }

          .profile-content::-webkit-scrollbar {
            display: none;
          }
          
          .update-btn {
            align-self: center;
          }

          input {
            align-self: center;
          }

          img, h1, h2, p {
            align-self: center;
          }

          h3 {
            margin: 15px;
            align-self: center;
          }

          

          
        }

      `}
      </style>
      <div className="profile-container">
        <div className="profile-darken"></div>
        <img
          src="https://images.rbxcdn.com/d82801b936c26e174c4e782510a72d78-roblox_logo_dark_08292022.svg"
          className="profile-logo"
          height="50"
        ></img>
        <button
          className="logout-btn"
          onClick={() =>
            logout({ logoutParams: { returnTo: window.location.origin } })
          }
        >
          Log Out
        </button>
        <div className="profile-content">
          <h1 className="welcome-title">Welcome!</h1>

          <img
            src={user.picture}
            alt="Profile"
            className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
            height="100"
            width="100"
          />
          <h2>{user.name}</h2>
          <p className=""> {user.email}</p>

          <input name="firstName" placeholder="First Name"></input>
          <input name="lastName" placeholder="Last Name"></input>
          <button className="update-btn">Update</button>

          <div className="divider" />
          <h3>ID Token</h3>
          <div className="tokenContainer">
            <Highlight>{JSON.stringify(user, null, 3)}</Highlight>
          </div>
          <div className="divider" />
          <h3>Access Token</h3>
          {accessToken && decodedAccessToken && (
            // <div className="inner-token-container">
            <div className="tokenContainer">
              <Highlight>
                {JSON.stringify(decodedAccessToken, null, 3)}
              </Highlight>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default withAuthenticationRequired(ProfileComponent, {
  onRedirecting: () => <Loading />,
});
