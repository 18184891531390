import React, { Fragment } from 'react';

import Hero from '../components/Hero';
import Content from '../components/Content';
import './home.css';

import { useAuth0 } from '@auth0/auth0-react';

const Home = () => {
  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
  } = useAuth0();
  return (
    <div className="main">
      <div className="profile-darken"></div>
      <img
        src="https://images.rbxcdn.com/d82801b936c26e174c4e782510a72d78-roblox_logo_dark_08292022.svg"
        className="home-logo"
        height="50"
      ></img>
      <button className="login-btn" onClick={() => loginWithRedirect()}>
        Log In
      </button>
    </div>
  );
};

export default Home;
