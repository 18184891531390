import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
export default function Login() {
  const { loginWithRedirect } = useAuth0();
  useEffect(() => {
    let url = new URL(window.location.href);
    let searchParams = new URLSearchParams(url.search);
    let invitation = searchParams.get('invitation') || null;
    let organization = searchParams.get('organization') || null;
    let organization_name = searchParams.get('organization_name');
    if (invitation && organization) {
      (async () => {
        await loginWithRedirect({
          authorizationParams: { invitation, organization },
        });
      })();
    } else {
      (async () => {
        await loginWithRedirect();
      })();
    }
  }, []);
  return <></>;
}
